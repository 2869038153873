import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Drawer,Box } from '@material-ui/core'
import {Button} from '@material-ui/core'
import {Typography} from '@material-ui/core'

import '../../../paginate.css'
// import { fetchCsrfToken } from './getToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAnglesRight, faCoffee } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { getBaseUrl,checkPermission } from '../customFunctions/customFunctions'
import Swal from 'sweetalert2';
import {useAuth} from '../auth/core/Auth'
import { toast,ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CheckBox } from '@material-ui/icons'

// const DailyReportPage: React.FC = () => (


// )
function formatMoney(amount:any){
    amount = parseFloat(amount)
    const formattedAmount = amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });

  return formattedAmount
}
interface Submerchant {
    id: string;
    name: string;
    email:string;
    phone: string;
    // Add any other fields based on your requirements
}
interface TransactionType {
    id: string;
    name: string;
    value:string;
    // Add any other fields based on your requirements
}

  interface Checklist {
    id: string;
    name: string;
    status: boolean;
  }
interface Transaction {
    number: string;
    amount: number;
    transaction_type_id: number;
    submerchant_id: string;
    card_id: string;
    batch: string;
    batch_amount: number;
    submerchant:Submerchant;
    collection_amount: number;
    terminal_number: string;
    cardholder: string;
    status: boolean;
    manual_entry: boolean;
    swiped_mag_stripe: boolean;
    time_created: string;
    transaction_type:TransactionType;
    t_type:string;
    // Add any other fields based on your requirements
}
interface Image{
    id:string;
    image:string;
}
interface Incidents{
    id:string;
    status:number;
    image:Image[];
    transaction:Transaction;
    checklist:Checklist[];
    notes:string;
    issue:string;
    analyst:string;
}
interface Rules{
    id:string;
    description:string;
    parameters:string;
    period:string;
    submerchant:Submerchant;
    status:string;
    value:number;
    decision:string;
}

const DisplayPage: FC = () => {
    const {auth, setCurrentUser} = useAuth()
    const user = auth?.user;
    const permissions = auth?.permissions;
    const [data,setData] = useState({current_page: 1, last_page: 1 });
    console.log(permissions)
    const [submerchants, setSubmerchants] =  useState<Submerchant[]>([]);
    const [image, setImage] = useState("");
    const element = <FontAwesomeIcon icon={faAngleRight} size='2x' />
    const [currentTransaction, setCurrentTransactions]  =useState<Incidents | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    

    






 



        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const base = getBaseUrl()
        const [itemOffset, setItemOffset] = useState(0);
        // const [CsrfToken,setCsrfToken] = useState("");
        const itemsPerPage = 12;
        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // const currentItems = transactions.slice(itemOffset, endOffset);
        // const pageCount = Math.ceil(transactions.length / itemsPerPage);
        const { logout } = useAuth()
        // const csrf_token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'); 
       
        const [isChecked, setIsChecked] = useState(false);
        const [types, setTypes] = useState<TransactionType[]>([]);
        const [formData, setFormData] = useState<{
            selectedSubmerchants: string[];
            type: string;
            parameters: string;
            value: string;
            change: string;
            time_period: string;
            decisions: string;
          }>({
            selectedSubmerchants: [],
            type: '',
            parameters: '',
            value: '',
            change: '',
            time_period: '',
            decisions: ''
          });

        const handleInputChange = (event:any) => {
            setFormData({
              ...formData,
              [event.target.name]: event.target.value
            });
          };
        
          const handleCheckboxChange = (event:any, submerchantId:string) => {
            if (event.target.checked) {
              setFormData(prev => ({
                ...prev,
                selectedSubmerchants: [...prev.selectedSubmerchants, submerchantId]
              }));
            } else {
              setFormData(prev => ({
                ...prev,
                selectedSubmerchants: prev.selectedSubmerchants.filter(id => id !== submerchantId)
              }));
            }
          };
        
          const handleSubmit = async (event:any) => {
            event.preventDefault();
            // console.log(formData);
            try {
              const response = await axios.post(base+'api/save-rules', formData);
              console.log(response.data);
            } catch (error) {
              console.error('An error occurred during the fetch', error);
            }
          };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/get-submerchants?page='+data.current_page); // Adjust the URL accordingly
            if (response.data) {
              const myData = response.data.data;
              setSubmerchants(myData);
              console.log(myData);
              setData({current_page: response.data.current_page, last_page: response.data.last_page });
              console.log(data)
            //   setTransactions(data);

            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };
        
        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        const fetchOptions = async () => {
            try {
                const response = await axios(base+'api/automation-options'); // Adjust the URL accordingly
                if (response.data) {
                const data = response.data;
                setTypes(response.data);
                console.log(types);
                //   setTransactions(data);
    
                } else {
                console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('An error occurred during the fetch', error);
            }
            };
        fetchData()
        fetchOptions()
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
       

       
      }, [data.current_page,logout]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Create Rule</PageTitle>
      <div className='flex space-x-15'>
       <ToastContainer />


    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Submerchants</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive '>
             <form onSubmit={handleSubmit}>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th></th>
                {/* <th className='min-w-50px'>ID</th> */}
                <th className='min-w-50px'>Name</th>
                <th className='min-w-100px'>phone</th>
                <th className='min-w-100px'>email</th>
              
            
                <th className='min-w-50px'>Status</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {submerchants.map((submerchant, index) => (
            <tr key={index} >
            {/* <td><input type="checkbox" name="selectedSubmerchants" className='form-control ' /></td> */}
           <td> 
           <input type="checkbox"
            name="selectedSubmerchants[]"
             value={submerchant.id }
             onChange={event => handleCheckboxChange(event, submerchant.id)}
             />
           </td>
              {/* <td className='text-dark fw-bold text-hover-primary fs-6' >{submerchant.id}</td> */}
              <td className='text-dark fw-bold text-hover-primary fs-6'>{submerchant.name}
            </td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>
                {submerchant.phone}
                </td>
              <td className='text-dark fw-bold fs-6'>{submerchant.email}</td>


            </tr>
          ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          <Box display="flex" justifyContent="center" alignItems="center" className='mb-10'>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setData({ ...data, current_page: data.current_page - 1 })}
              disabled={data.current_page === 1}
            >
              Previous
            </Button>

            <Typography variant="subtitle1" style={{ margin: '0 10px' }}>
              Page {data.current_page} of {data.last_page}
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setData({ ...data, current_page: data.current_page + 1 })}
              disabled={data.current_page === data.last_page}
            >
              Next
            </Button>
        </Box>
        
          <select className="form-select mb-10" aria-label="Select example" name="type"  onChange={handleInputChange}>
            <option>Select Transaction Type</option>
            <option value="Credit Card.Sale">Sale</option>
            <option value="Credit Card.Refund">Refund</option>
            <option value="Credit Card.Chargeback">Chargeback</option>
          
            {/* {types.map((type, index) => (
            <option value={type.value}>{type.name}</option>
            ))} */}

        </select>
        <select className="form-select mb-10" aria-label="Select example" name="parameters"  onChange={handleInputChange}>
            <option>Select Parameters</option>
            <option value="amount">Amount</option>
            <option value="volume">Count</option>
            <option value="average">Average</option>
        </select>
        <div className="mb-10">
                <label className="form-label">Amount</label>
                <input
                    type="text"
                    className="form-control form-control-white"
                    placeholder="0.00"
                    name="value"
                    onChange={handleInputChange}
                />
        </div>
        <div className="mb-10 row">
                 <label className="form-label">
                     Monitor Changes ?
                    </label>
                <div className="form-check form-check-custom form-check-solid col-4 m-3">
                    <input className="form-check-input" type="radio" value="increase" id="flexRadioDefault" name="change"  onChange={handleInputChange}/>
                    <label className="form-check-label">
                       Increase
                    </label>
                </div>
                <div className="form-check form-check-custom form-check-solid col m-3">
                    <input className="form-check-input" type="radio" value="decrease" id="flexRadioDefault" name="change"  onChange={handleInputChange}/>
                    <label className="form-check-label">
                        Decrease
                    </label>
                </div>
        </div>

                <select className="form-select mb-10" aria-label="Select example" name="time_period"  onChange={handleInputChange}>
                <option>Time Period</option>
                <option value="daily">Daily</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
                </select>
                <select className="form-select mb-10" aria-label="Select example" name="decisions"  onChange={handleInputChange}>
                <option>Decision</option>
                <option value="move_to_watchlist">Add to Watch List</option>
                <option value="suspend_account">Suspend Account</option>
               
                </select>
                <button type="submit"  className="btn btn-success">Create Rule</button>
                </form>

        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>


  
    </div>
    </>
  )
}

export default DisplayPage
