import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import { getBaseUrl } from '../customFunctions/customFunctions'
import { formatDate } from '../customFunctions/customFunctions'
import { useAuth } from '../auth'
import axios from 'axios'

// const DailyReportPage: React.FC = () => (

interface Transaction{
  submerchant:Submerchant[];
  number:string;
  amount:string;
  transaction_type:{
    name:string;
  };
  t_type:string;
  issue:string
}

interface Submerchant{
  id:string,
  name:string;
  email:string;
  phone:string;
  status:number;
  address:string;
}
interface File{
  id:string;
  name:string;
  type:string;
  document:string;
  created_at:string;
  updated_at:string;
}
interface Notes{
  id:string;
  note:string;
  analyst:string;
  created_at:string;
  updated_at:string;
}

const SubmerchantTransactionPage: FC = () => {
  const base = getBaseUrl()
    const { subMerchant } = useParams();

    const [transactions, setTransactions] = useState<Transaction[] |[] >([]);
    const [submerchant, setSubmerchant] =useState<Submerchant | null >(null);
    const [files, setFiles] = useState<File[] |[] >([]);
    const [notes, setNotes] = useState<Notes[] |[] >([]);
    const uploadFiles = async (id:string | undefined) => {
      const files = (document.getElementById('file') as HTMLInputElement).files;
      const name = (document.getElementById('name') as HTMLInputElement).value;
      const formData = new FormData();
      formData.append("name",name as string)
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append("file[]",files[i]);
        }
        const response = await axios.post(base+'api/upload-documents/'+id,formData, 
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
        if (response.status === 200) {
          const data = response.data;
          setFiles([...data.documents]);
        } else {
          console.error('Failed to upload files');
        }
      }
    }
    const deleteDocument = async (id:string) => {
      const response = await axios.get(base+'api/delete-document/'+id)
      if (response.status === 200) {
        const data = response.data;
        setFiles([...data.documents]);
      } else {
        console.error('Failed to delete document');
      }
    }
    const handleViewImage = async (image:string)=>{
      const base = getBaseUrl();
      console.log(image)
  
      try {
        const response = await axios.post(`${base}api/get-image`, { image });
      
        if (response.data) {
          const url = response.data;
          window.open(url, '_blank');
        } else {
          console.error('Failed to fetch Image');
        }
      } catch (error) {
        console.error('An error occurred during the fetch', error);
      }
        

  }
    const deleteNote = async (id:string) => {
      const response = await axios.get(base+'api/delete-note/'+id)
      if (response.status === 200) {
        const data = response.data;
        setNotes([...data.notes]);
      } else {
        console.error('Failed to delete note');
      }
    }
    const {logout} = useAuth();
    const addNote = async (id:string | undefined) => {
      const note = (document.getElementById('note') as HTMLInputElement).value;
      
      const formData = new FormData();
      formData.append("notes",note as string)
      if (note) {
        const response = await axios.post(base+'api/add-note/'+id,formData, 
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
        })
        if (response.status === 200) {
          const data = response.data;
        
         
          setNotes([...data.notes]);
          const noteInput = document.getElementById('note') as HTMLInputElement;
          if (noteInput) {
            noteInput.value = '';
          } else {
            console.error('Failed to find note input element');
          }
        
        } else {
          console.error('Failed to add note');
        }
      }
    }
  

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/file/'+ subMerchant ); // Adjust the URL accordingly
            if (response.data) {
              const data = response.data;
              setTransactions(data.transactions);
            
              setSubmerchant(data);
              setFiles(data.documents);
              setNotes(data.notes);
            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };

        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        fetchData()
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
      }, [logout]);

      useEffect(() => {
        console.log('Notes updated:', notes);
      }, [notes]);
  return (
    <>

      <PageTitle breadcrumbs={[]}>{submerchant?.name}</PageTitle>
      <div className='card mb-4'>  
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Submerchant Details</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>
        </div>
        <div className='card-body'>

          <div className='row'>
            <div className='col-6'>
              <h3 className='fw-bold fs-6 '>Name</h3>
              <span className='text-muted fw-bold fs-6'>{submerchant?.name}</span>
            </div>
            <div className='col-6 mt-4'>
              <h3 className='fw-bold fs-6'>Email</h3>
              <span className='text-muted fw-bold fs-6 mb-3'>{submerchant?.email}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <h3 className='fw-bold fs-6'>Phone</h3>
              <span className='text-muted fw-bold fs-6 mb-3'>{submerchant?.phone}</span>
            </div>
            <div className='col-6 mt-4'>
              <h3 className='fw-bold fs-6'>Status</h3>
              <span className='text-muted fw-bold fs-6 mb-3'>{submerchant?.status === 1 ? 'Active' : 'Inactive'}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col-6'>
              <h3 className='fw-bold fs-6'>Address</h3>
              <span className='text-muted fw-bold fs-6 mb-3'>{submerchant?.address}</span>
            </div>
          </div>
        </div>

      
      </div>
     
      <div className='row'>
   <div className={`card col mx-4`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Last 10 Transactions For {submerchant?.name}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 row'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-100px'>TID</th>
                <th className='min-w-100px'>Amount</th>
                <th className='min-w-100px'>Type</th>
              

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {transactions?.slice(0,8).map((transaction:any, index:any) => (
            <tr key={index}>
              <td className='text-dark fw-bold text-hover-primary fs-6' >{transaction.number}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{transaction.amount}</td>
             
              <td className='text-dark fw-bold text-hover-primary fs-6'>{transaction.t_type}</td>
              

            </tr>
          ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          <a href="/transactions" className="text-success">View All Transactions {">>>"}</a>
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <div className={`card col`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Documents</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 row' id="documents">
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-100px'>Name</th>
                <th className='min-w-100px'>Type</th>
                <th className='min-w-100px'>Date</th>
                <th className='min-w-100px'>Action</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {files?.map((file:any, index:any) => (
            <tr key={index}>
              <td className='text-dark fw-bold text-hover-primary fs-6' >{file.document_name}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{file.document_type}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{formatDate(file.created_at)}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>
              <span className="col text-primary cursor-pointer" onClick={()=>handleViewImage(file.document)}>View</span>
                <a href="#documents" className="mx-3 text-danger" onClick={()=>deleteDocument(file.id)}>Delete</a>
              
              </td>

            </tr>
          ))}

            </tbody>
            {/* end::Table body */}
          </table>
        <div className="d-flex justify-content-end">
          <input type="text" className='form-control mx-3' name="name" id="name" placeholder="Document Name"/>
          <input type="file" className='form-control mx-3' name="file[]" id="file" multiple/>
          <button className="btn btn-primary" onClick={()=>uploadFiles(submerchant?.id)}>Upload</button>
         </div> 
          {/* end::Table */}
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}

    </div>
   
   
    </div>

    <div className='card mt-4' id="notes">  
      <div className='card-header border-0 pt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Notes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>
        </div>
      <div className='card-body py-4'>
      <div className='row'>
        <div className='col-12'>
          {notes?.map((note:any, index:any) => (
            <div key={index} className="justify-content-between my-5">
              <p className='text-dark fw-bold text-hover-primary fs-6 my-1'>{note.notes}</p>
              <span>{note.analyst}</span><br />
              <span className='mb-4'>{formatDate(note.created_at)}</span><br />
              <a href="#notes" className="text-danger" onClick={()=>deleteNote(note.id)}>Delete</a>
              </div>
              ))}
        </div>
        </div>
        <textarea className='form-control mt-4' placeholder="Add Note" name="note" id="note"></textarea>
        <button className="btn btn-primary mt-3" onClick={()=> addNote(submerchant?.id)}>Add Note</button>
        </div>
        </div>
   
        
    
    </>
  )
}

export default SubmerchantTransactionPage
