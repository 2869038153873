import { useAuth } from "../auth";
const API_URL = process.env.REACT_APP_API_URL

const getBaseUrl = () => {
  console.log("Hiiiiiiiiiiiiiii")
  return 'https://trms-api.rapidcents.com/'
  // return 'http://localhost:8000/'
};
const formatDate = (date: string) => {
  const d = new Date(date); 
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
  return d.toLocaleDateString(undefined, options);

}
interface Permissions {
 name: string,
}
const checkPermission = (permissions: Permissions[] | undefined, permission: string) => {
  // console.log(permissions.find(p => p.permissions_id === permission) ? true : false)
  return permissions?.find(p => p.name == permission) ? true : false;
}
const downloadCSV = () => {
    // Get the table
    var table = document.getElementById('table') as HTMLTableElement;

    // Prepare CSV content
    var csvContent = "data:text/csv;charset=utf-8,";

    // Add header row
    var headerRow = table.rows[0];
    for (var j = 0; j < headerRow.cells.length; j++) {
      csvContent += '"' + headerRow.cells[j].innerText + '"' + (j < headerRow.cells.length - 1 ? ',' : '\n');
    }

    // Add data rows
    for (var i = 1; i < table.rows.length; i++) {
      var dataRow = table.rows[i];
      for (var j = 0; j < dataRow.cells.length; j++) {
        csvContent += '"' + dataRow.cells[j].innerText + '"' + (j < dataRow.cells.length - 1 ? ',' : '\n');
      }
    }


    // Create a download link
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "report.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  export {downloadCSV,getBaseUrl,formatDate,checkPermission}
