import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import { getBaseUrl } from '../customFunctions/customFunctions'
import axios from 'axios'
import { useAuth } from '../auth'


// const DailyReportPage: React.FC = () => (


// )
interface Submerchant {
    id: string;
    name: string;
    email:string;
    phone: string;
    // Add any other fields based on your requirements
}
interface watchType {
    id: string;
    name: string;
    // Add any other fields based on your requirements
}
interface watch {
    number: string;
    amount: number;
    watch_type_id: number;
    submerchant_id: string;
    card_id: string;
    batch: string;
    batch_amount: number;
    submerchant:Submerchant;
    collection_amount: number;
    terminal_number: string;
    cardholder: string;
    status: boolean;
    manual_entry: boolean;
    swiped_mag_stripe: boolean;
    time_created: string;
    watch_type:watchType
    // Add any other fields based on your requirements
}
const DailyWatchlistPage: FC = () => {
    const { subMerchant } = useParams();
    const base = getBaseUrl()
    const [watch, setwatch] = useState<Submerchant[] | []>([]);
    const { logout } = useAuth()


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/watchlist/daily'); // Adjust the URL accordingly
            if (response.data) {
              const data = await response.data;
              setwatch(data);

            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };

        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        fetchData()
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
}, [logout]);
  return (
    <>
      <PageTitle breadcrumbs={[]}>Submerchants Added To Watch List</PageTitle>
      <>
   <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>All Submerchants</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 row'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-100px'>Submerchant</th>
                <th className='min-w-100px'>Address</th>
                <th className='min-w-150px'>Email</th>
                <th className='min-w-100px'>Phone</th>
                <th className='min-w-100px'>Reason</th>
                <th className='min-w-100px'>Timing</th>


              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {watch ?
            watch.map((watch:any, index:any) => (
            <tr key={index}>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{watch.submerchant.name}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{watch.submerchant.email}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{watch.submerchant.address}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{watch.submerchant.phone}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{watch.reason}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{watch.timings}</td>


            </tr>
          )):<p>not found</p>
        }

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    </>
    </>
  )
}

export default DailyWatchlistPage
