import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
// import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import ReactPaginate from 'react-paginate'
import {Drawer, Box} from '@material-ui/core'
// import { Pagination } from 'react-bootstrap'
// import '../../../../../css/paginate.css'
// import '../../../../public/paginate.css'
import '../../../paginate.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { useAuth } from '../auth'
import { getBaseUrl } from '../customFunctions/customFunctions'
import Swal from 'sweetalert2';
// const DailyReportPage: React.FC = () => (


// )
function formatMoney(amount:any){
    amount = parseFloat(amount)
    const formattedAmount = amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });

  return formattedAmount
}
interface Submerchant {
    id:string,
    name:string;
    email:string;
    phone:string;
    status:number;
    address:string;

    }
interface MSubmerchant {
        id:string;
        submerchant_id:string,
        name:string;
        volume:number;
        count:number;
        avergae:number;
        email:string;
        address:string;
        image:string;
        submerchant:Submerchant;
        status:number;
        checklist:Checklist[];
        issue:string;

        }
    // Add any other fields based on your requirements


  interface Checklist {
    id: string;
    name: string;
    status: boolean;

  }
const OLReportPage: FC = () => {
    const { logout } = useAuth();
    const [submerchants, setSubmerchants] = useState<MSubmerchant []>([]);
    const [image, setImage] = useState("");
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // const element = <FontAwesomeIcon icon={faAngleRight} size='2x' />
    const [currentSubmerchant, setcurrentSubmerchants] = useState<MSubmerchant | null>(null);
        // const [currentChecklist,setCurrentChecklist] = useState({
        //     checkName :"",
        //     "enabled":false}
        // )
    // const [issue, setIssue] = useState('');
    function handleSelect(submerchant:any){
        setcurrentSubmerchants((prevsubmerchant) =>
        prevsubmerchant === submerchant ? { ...prevsubmerchant } : submerchant
      );
      setIsDrawerOpen(true);
    }
    const handleViewImage = async (image:string)=>{
      const base = getBaseUrl();
      console.log(image)
  
      try {
        const response = await axios.post(`${base}api/get-image`, { image });
      
        if (response.data) {
          const url = response.data;
          window.open(url, '_blank');
        } else {
          console.error('Failed to fetch Image');
        }
      } catch (error) {
        console.error('An error occurred during the fetch', error);
      }
        

  }
    function imageSelection(e:any) {
        var myCurrent = currentSubmerchant;
        setImage(URL.createObjectURL(e.target.files[0]));
        handleSelect(myCurrent);
    }
    const closeDrawer = () => {
      setIsDrawerOpen(false);
    };
    const handleSubmit = async (e:any)=>{
        const inputElement = document.getElementById('image')as HTMLInputElement;;
        // console.log(inputElement.files[0].name);
        try {
            // Make a POST request to the Laravel backend
            const response = await axios.post('/daily_report', {
              "id":currentSubmerchant?.id,
            //   "submerchant_id":currentSubmerchant.submerchant_id,
            //   "issue":currentSubmerchant.issues,
              "image":inputElement?.files?.[0]
          },
          {headers: {
            'Content-Type': 'multipart/form-data', // Set Content-Type to handle file uploads
          }
      });
          console.log(response.data)
          Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'submerchant approved succesfully',
            }).then(() => {
              // Reload the page after the SweetAlert is closed

              window.location.reload();
            });
          } catch (error) {
            // Handle error
            console.error('Error creating resolution:', error);
          }
    }
    const handleChecks = () => {

            return currentSubmerchant?.checklist.every(item => item.status);
          };


    function handleCheck(submerchant:any,checklisubmerchant_id:any){
        var mysubmerchant = submerchants;
        // var specific
        mysubmerchant.map((tran => {
                if(tran.id===submerchant.id){
                    tran.checklist.map(myc=>{
                        if(myc.id === checklisubmerchant_id){
                                myc.status = !myc.status
                                handleSelect(tran);
                                setSubmerchants(mysubmerchant)


                        }
                    })
                }

        }

        ))


    }

        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const [itemOffset, setItemOffset] = useState(0);
        const base = getBaseUrl()
        const itemsPerPage = 12;
        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = submerchants.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(submerchants.length / itemsPerPage);

        // Invoke when user click to request another page.
        const handlePageClick = (event:any) => {
          const newOffset = (event.selected * itemsPerPage) % submerchants.length;
          console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
          );
          setItemOffset(newOffset);
        };
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/ol_report'); // Adjust the URL accordingly
            if (response.data) {
              const data = await response.data;
              setSubmerchants(data);

            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };
        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        fetchData()
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
}, [logout]);
  return (
    <>
      <PageTitle breadcrumbs={[]}>Monthly Report</PageTitle>
      <div className='space-x-15'>

   <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Incidents</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive '>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-50px'>submerchant_id</th>
                <th className='min-w-200px'>Submerchant</th>
                <th className='min-w-100px'>No of submerchants</th>
                <th className='min-w-50px'>Volume</th>
                <th className='min-w-100px'>Average</th>
                <th className='min-w-100px'>Issue</th>
                <th className='min-w-50px'>Status</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {currentItems.map((submerchant, index) => (
            <tr key={index} onClick={()=>handleSelect(submerchant)}>
              <td className='text-dark fw-bold text-hover-primary fs-6' >{submerchant.submerchant_id}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6' >{submerchant.submerchant.name}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6' >{submerchant.count}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{ formatMoney(submerchant.volume)}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{ formatMoney(submerchant.avergae)}
            </td>
              <td className='text-dark fw-bold fs-6'>{submerchant.issue}</td>
              <td className='text-dark fw-bold fs-6'>
                    {submerchant.status == 1 ? (
                        <span className="badge badge-light-success">Reviewed</span>
                    ) : submerchant.status == 0 ? (
                        <span className="badge badge-light-danger">Awaiting</span>
                    ) : (
                        <span className="badge badge-light-secondary">Unknown Status</span>
                    )}
</td>

            </tr>
          ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
          <ReactPaginate
        breakLabel="..."
        nextLabel={<FontAwesomeIcon icon={faAngleRight} size='xl' />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={<FontAwesomeIcon icon={faAngleLeft} size='xl' />}
        renderOnZeroPageCount={null}
       className="react-paginate"
      />

        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
    <Box sx={{ width: 550 }} role="presentation" className='p-5'>
        <div className='card-header cursor-pointer p-9'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Submerchant Details</h3>
          </div>


        </div>
        {currentSubmerchant?.submerchant_id!==""?
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Name</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{currentSubmerchant?.submerchant.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Company Address</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{currentSubmerchant?.submerchant.address}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Phone
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Phone number must be active'
              ></i>
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{currentSubmerchant?.submerchant.phone}</span>

              <span className='badge badge-success'>Verified</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Email</label>

            <div className='col-lg-8'>
              <a href='#' className='fw-bold fs-6 text-dark text-hover-primary'>
                {currentSubmerchant?.submerchant.email}
              </a>
            </div>
          </div>


          {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
             Images
              <i
                className='fas fa-exclamation-circle ms-1 fs-7'
                data-bs-toggle='tooltip'
                title='Images'
              ></i>
            </label>

            <div className='col-lg-8'>
              <img   className='col-lg-8' src={currentSubmerchant?.image ? `/images/uploads/${currentSubmerchant.image}` : image} />
            </div> 
          </div> */}

        {/* <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Actions</label>

            <div className='col-lg-8'>

          {currentSubmerchant?.checklist.map((checklist:any,index:any)=>(

            <div className="form-check mb-5" key={index}>
            <input className="form-check-input" type="checkbox"
            // value={checklist.enabled}
            checked={checklist.status}
            onChange={() => handleCheck(currentSubmerchant, checklist.id)}
            />
            <label className="form-check-label" >
             {checklist.name}
            </label>
          </div>
          ))}

        <div className="form-check mb-5" >
            <input className="form-control" type="file"
            onChange={imageSelection}
            name='image'
            id="image"

            />

          </div>
            </div>
        </div>


        <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Decision</label>

            <div className='col-lg-8'>
            <button
            disabled = {!handleChecks()}
            style={{border:"1px solid"}}
            type="button"
            className="btn text-success text-hover-white btn-outline-success btn-sm m-1"
            onClick={handleSubmit}
            >Approve
            </button>
            <button type="button" className="btn btn-danger btn-sm m-1">Reject</button>

            </div>


        </div> */}



          <Link to={'submerchant/report/'+currentSubmerchant?.submerchant_id} className='fw-bold fs-6 text-success text-hover-primary'>
            Submerchant Report {">>>"}
          </Link>


        </div> : <p className='text-muted'>No Submerchant Selected</p>}
      </Box>
      </Drawer>
    </div>
    </>
  )
}

export default OLReportPage
