import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Drawer,Box } from '@material-ui/core'
import {Button} from '@material-ui/core'
import {Typography} from '@material-ui/core'
import { useParams } from 'react-router-dom'

import '../../../paginate.css'
// import { fetchCsrfToken } from './getToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAnglesRight, faCoffee } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { getBaseUrl,checkPermission } from '../customFunctions/customFunctions'
import Swal from 'sweetalert2';
import {useAuth} from '../auth/core/Auth'
import { toast,ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CheckBox } from '@material-ui/icons'

// const DailyReportPage: React.FC = () => (


// )


interface Submerchant {
    id: string;
    name: string;
    mcc: string;
    dba_name: string;
    legal_name: string;
    tax_id: string;
    business_type: string;
    processing_limit: string;
    card_network_graduation_limits: string;
    approved_volume: string;
    isActive: boolean;
    ticketsMaxAmount: string;
    collection_balance: string;
    batch_thresholds: string;
    address: string;
    email: string;
    foreignCardCount: string;
    grossSalesCount: string;
    foreignCardMax: string;
    dailyDepositMaxAmount: string;
    chargeback_threshold: string;
    refund_threshold: string;
    monthly_processing_limit: string;
    weekly_chargeback_threshold: string;
    weekly_refund_threshold: string;
    phone: string;
    location: string;
    city: string;
    state: string;
    zip: string;
    status: string;
    country: string;
    street: string;
    province: string;
    underwriting: string;
    refundPercentageMax: string;
    refundCountPercentageMax: string;
    cardAuthNumberLimit: string;
    cardTransactionNumberLimit: string;
    cardDeclinedNumberLimit: string;
    creditRefundMax: string;
    last_activity: string;
  }



const SubmerchantPanel: FC = () => {
    const { subMerchant } = useParams();
   
    const {auth, setCurrentUser} = useAuth()
    const user = auth?.user;
    const permissions = auth?.permissions;
    const [submerchant,setSubmerchant] = useState<Submerchant>();
    // const [roles,setRoles] = useState<Roles[]>([]);
    const [data,setData] = useState({current_page: 1, last_page: 1 });
    console.log(permissions)

    const [image, setImage] = useState("");
    const element = <FontAwesomeIcon icon={faAngleRight} size='2x' />
  
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    

    






 



        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const base = getBaseUrl()
       
        // const currentItems = transactions.slice(itemOffset, endOffset);
        // const pageCount = Math.ceil(transactions.length / itemsPerPage);
        const { logout } = useAuth()
        // const csrf_token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'); 
       
        const [isChecked, setIsChecked] = useState(false);
       
        interface FormData {
            id: string;
            processing_limit: string;
            card_network_graduation_limits: string;
            approved_volume: string;
            maxAmount: string;
            collection_balance: string;
            batch_thresholds: string;
            foreignCardCount: string;
            grossSalesCount: string;
            foreignCardMax: string;
            dailyDepositMaxAmount: string;
            chargeback_threshold: string;
            refund_threshold: string;
            monthly_processing_limit: string;
            weekly_chargeback_threshold: string;
            weekly_refund_threshold: string;
            refundPercentageMax: string;
            refundCountPercentageMax: string;
            cardAuthNumberLimit: string;
            cardTransactionNumberLimit: string;
            cardDeclinedNumberLimit: string;
            creditRefundMax: string;
          }
          
          const [formData, setFormData] = useState<FormData>({
            id: '',
            processing_limit: '',
            card_network_graduation_limits: '',
            approved_volume: '',
            maxAmount: '',
            collection_balance: '',
            batch_thresholds: '',
            foreignCardCount: '',
            grossSalesCount: '',
            foreignCardMax: '',
            dailyDepositMaxAmount: '',
            chargeback_threshold: '',
            refund_threshold: '',
            monthly_processing_limit: '',
            weekly_chargeback_threshold: '',
            weekly_refund_threshold: '',
            refundPercentageMax: '',
            refundCountPercentageMax: '',
            cardAuthNumberLimit: '',
            cardTransactionNumberLimit: '',
            cardDeclinedNumberLimit: '',
            creditRefundMax: '',
          });

        const handleInputChange = (event:any) => {
            setFormData({
              ...formData,
              [event.target.name]: event.target.value
            });
          };
        

        
          const handleSubmit = async (event:any) => {

            event.preventDefault();
            // console.log(formData);
            const dataToSubmit = {
                ...formData,
                id: submerchant?.id,
              };
      
            try {
              const response = await axios.post(base+'api/save-controls', dataToSubmit);
              console.log(response.data);
              window.location.reload();
            } catch (error) {
              console.error('An error occurred during the fetch', error);
            }
          };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/file/'+ subMerchant ); // Adjust the URL accordingly
            if (response.data) {
              const myData = response.data;
              setSubmerchant(myData);
              console.log(myData);
              setData({current_page: response.data.current_page, last_page: response.data.last_page });
              console.log(data)
            //   setTransactions(data);

            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };
        
        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
   
        fetchData()
       
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
       

       
      }, [data.current_page,logout]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Adjust Controls</PageTitle>
      <div className='flex space-x-15'>
       <ToastContainer />


    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{submerchant?.name}</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive '>
             <form onSubmit={handleSubmit} className='row'>
                
             <div className="mb-10 col-3">
             <label className="form-label">Processing Limit</label>
             <input type="number" onChange={handleInputChange} 
             name="processing_limit" placeholder={submerchant?.processing_limit}
             className="form-control form-control-white"
             />
             </div>

            <div className="mb-10 col-3">
            <label className="form-label">Card Graduation Limits</label>
            <input type="number"
            onChange={handleInputChange} 
            name="card_network_graduation_limits" 
            placeholder={submerchant?.card_network_graduation_limits} 
            className="form-control form-control-white"
            />
 </div>
 <div className="mb-10 col-3">
  <label className="form-label">Approved Volume</label>
  <input type="number" onChange={handleInputChange} name="approved_volume" placeholder={submerchant?.approved_volume} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Max Amount</label>
  <input type="number" onChange={handleInputChange} name="maxAmount" placeholder={submerchant?.ticketsMaxAmount} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Collection Balance</label>
  <input type="number" onChange={handleInputChange} name="collection_balance" placeholder={submerchant?.collection_balance} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Batch Thresholds</label>
  <input type="number" onChange={handleInputChange} name="batch_thresholds" placeholder={submerchant?.batch_thresholds} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Foreign Card Count</label>
  <input type="number" onChange={handleInputChange} name="foreignCardCount" placeholder={submerchant?.foreignCardCount} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Gross Sales Count</label>
  <input type="number" onChange={handleInputChange} name="grossSalesCount" placeholder={submerchant?.grossSalesCount} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Foreign Card Max</label>
  <input type="number" onChange={handleInputChange} name="foreignCardMax" placeholder={submerchant?.foreignCardMax} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Daily Deposit Max Amount</label>
  <input type="number" onChange={handleInputChange} name="dailyDepositMaxAmount" placeholder={submerchant?.dailyDepositMaxAmount} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Chargeback Threshold</label>
  <input type="number" onChange={handleInputChange} name="chargeback_threshold" placeholder={submerchant?.chargeback_threshold} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Refund Threshold</label>
  <input type="number" onChange={handleInputChange} name="refund_threshold" placeholder={submerchant?.refund_threshold} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Monthly Processing Limit</label>
  <input type="number" onChange={handleInputChange} name="monthly_processing_limit" placeholder={submerchant?.monthly_processing_limit} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Weekly Chargeback Threshold</label>
  <input type="number" onChange={handleInputChange} name="weekly_chargeback_threshold" placeholder={submerchant?.weekly_chargeback_threshold} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Weekly Refund Threshold</label>
  <input type="number" onChange={handleInputChange} name="weekly_refund_threshold" placeholder={submerchant?.weekly_refund_threshold} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Refund Percentage Max</label>
  <input type="number" onChange={handleInputChange} name="refundPercentageMax" placeholder={submerchant?.refundPercentageMax} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Refund Count Percentage Max</label>
  <input type="number" onChange={handleInputChange} name="refundCountPercentageMax" placeholder={submerchant?.refundCountPercentageMax} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Card Auth Number Limit</label>
  <input type="number" onChange={handleInputChange} name="cardAuthNumberLimit" placeholder={submerchant?.cardAuthNumberLimit} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Card Transaction Number Limit</label>
  <input type="number" onChange={handleInputChange} name="cardTransactionNumberLimit" placeholder={submerchant?.cardTransactionNumberLimit} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Card Declined Number Limit</label>
  <input type="number" onChange={handleInputChange} name="cardDeclinedNumberLimit" placeholder={submerchant?.cardDeclinedNumberLimit} className="form-control form-control-white" />
</div>

<div className="mb-10 col-3">
  <label className="form-label">Credit Refund Max</label>
  <input type="number" onChange={handleInputChange} name="creditRefundMax" placeholder={submerchant?.creditRefundMax} className="form-control form-control-white" />
</div>


            


                <div className='col-12'>
                <button type="submit"  className="btn btn-success">Adjust Controls</button>
                </div>
                </form>

        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>


  
    </div>
    </>
  )
}

export default SubmerchantPanel
