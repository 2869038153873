import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Drawer,Box } from '@material-ui/core'
import {Button} from '@material-ui/core'
import {Typography} from '@material-ui/core'

import '../../../paginate.css'
// import { fetchCsrfToken } from './getToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAnglesRight, faCoffee } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { getBaseUrl,checkPermission } from '../customFunctions/customFunctions'
import Swal from 'sweetalert2';
import {useAuth} from '../auth/core/Auth'
import { toast,ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CheckBox } from '@material-ui/icons'

// const DailyReportPage: React.FC = () => (


// )

interface TransactionType {
    id: string;
    name: string;
    value:string;
    // Add any other fields based on your requirements
}

  interface Checklist {
    id: string;
    name: string;
    status: boolean;
  }



const Ttype: FC = () => {
    const {auth, setCurrentUser} = useAuth()
    const user = auth?.user;
    const permissions = auth?.permissions;
    const [ttypes,setTtypes] = useState<TransactionType[]>([]);
    const [data,setData] = useState({current_page: 1, last_page: 1 });
    console.log(permissions)

    const [image, setImage] = useState("");
    const element = <FontAwesomeIcon icon={faAngleRight} size='2x' />
  
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    

    






 



        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const base = getBaseUrl()
       
        // const currentItems = transactions.slice(itemOffset, endOffset);
        // const pageCount = Math.ceil(transactions.length / itemsPerPage);
        const { logout } = useAuth()
        // const csrf_token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'); 
       
        const [isChecked, setIsChecked] = useState(false);
        const [types, setTypes] = useState<TransactionType[]>([]);
        const [formData, setFormData] = useState<{
            name: string;
            value: string;

          }>({
        
            name: '',
    
            value: ''
          });

        const handleInputChange = (event:any) => {
            setFormData({
              ...formData,
              [event.target.name]: event.target.value
            });
          };
        

        
          const handleSubmit = async (event:any) => {
            event.preventDefault();
            // console.log(formData);
            try {
              const response = await axios.post(base+'api/save-types', formData);
              console.log(response.data);
              window.location.reload();
            } catch (error) {
              console.error('An error occurred during the fetch', error);
            }
          };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/get-types?page='+data.current_page); // Adjust the URL accordingly
            if (response.data) {
              const myData = response.data.data;
              setTtypes(myData);
              console.log(myData);
              setData({current_page: response.data.current_page, last_page: response.data.last_page });
              console.log(data)
            //   setTransactions(data);

            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };
        
        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        const fetchOptions = async () => {
            try {
                const response = await axios(base+'api/automation-options'); // Adjust the URL accordingly
                if (response.data) {
                  console.log(response.data);
                const data = response.data;
                setTtypes(response.data);
                console.log(ttypes);
                //   setTransactions(data);
    
                } else {
                console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('An error occurred during the fetch', error);
            }
            };
       
        fetchData()
        fetchOptions()
      
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
       

       
      }, [data.current_page,logout]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Create Rule</PageTitle>
      <div className='flex space-x-15'>
       <ToastContainer />


    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Submerchants</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive '>
             <form onSubmit={handleSubmit}>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
              
                <th className='min-w-50px'>SN</th>
                <th className='min-w-50px'>Name</th>
                <th className='min-w-100px'>Value</th>
            
              
      

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {(ttypes || []).map((ttype, index) => (
            <tr key={index}>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{index + 1}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{ttype.name}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{ttype.value}</td>
            </tr>
          ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          <Box display="flex" justifyContent="center" alignItems="center" className='mb-10'>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setData({ ...data, current_page: data.current_page - 1 })}
              disabled={data.current_page === 1}
            >
              Previous
            </Button>

            <Typography variant="subtitle1" style={{ margin: '0 10px' }}>
              Page {data.current_page} of {data.last_page}
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setData({ ...data, current_page: data.current_page + 1 })}
              disabled={data.current_page === data.last_page}
            >
              Next
            </Button>
        </Box>
        
        <div className="mb-10">
                <label className="form-label">Name</label>
                <input
                    type="text"
                    className="form-control form-control-white"
                    placeholder="Credit"
                    name="name"
                    onChange={handleInputChange}
                />
        </div>

        <div className="mb-10">
                <label className="form-label">Value</label>
                <input
                    type="text"
                    className="form-control form-control-white"
                    placeholder="0.00"
                    name="value"
                    onChange={handleInputChange}
                />
        </div>



                <button type="submit"  className="btn btn-success">Create Rule</button>
                </form>

        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>


  
    </div>
    </>
  )
}

export default Ttype
