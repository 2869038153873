import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import axios from 'axios'
import { useAuth } from '../auth'
import { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import {downloadCSV, getBaseUrl} from '../customFunctions/customFunctions'



// const DailyReportPage: React.FC = () => (


// )
interface Submerchant{
  id:string;
  dba_name:string;
  
  }
interface Report{
    total:number;
    submerchant:Submerchant
    average:number;
    count:number;
    refund:number;
    refundVolume:number;
    chargeback:number;
    chargebackVolume:number;
    refundRatio:number;
    chargeBackRatio:number;
    chargeback_status:string;
    refund_status:string;
}
function formatMoney(amount:any){
    if(!amount){
       return "$0.00"
    }
    amount = parseFloat(amount)
    const formattedAmount = amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });

  return formattedAmount
}
function getSalesPercentage(value1:any,value2:any){
    if(value1 == '0.00'  && value2!= '0.00'|| value1==null){
        if(value2==null){
            return 0;
        }

        return -100;
   }
   if(value1=='0.00' && value2 == '0.00'){
    return 0;
   }
   if(value1!='0.00' && value2!='0.00' && value1!==null && value2!== null){
    return Math.ceil((parseFloat(value1) - parseFloat(value2))/(parseFloat(value1))*100)
}
return 100
}
function getPercentage(value1:any,value2:any){
    if(value1!=null && value2 !=null){
        value1 = parseFloat(value1);
        value2 = parseFloat(value2);
        if (value2 === 0) {
            // Avoid division by zero
            return 100;
        }
        let difference = value1 - value2
        return Math.ceil((difference / Math.abs(value2)) * 100);
    }
}
const SubmerchantReportPage: FC = () => {
    const { subMerchant } = useParams();
    const { logout } = useAuth()
    const base = getBaseUrl()
    const reportNumbers =[0,1,2]
    const [reports, setReports] = useState<Report[]>([]);
    const [currentSubmerchant, setCurerentSubmerchant] = useState('');
    const [issue, setIssue] = useState('');

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/submerchant/getWeeklyReport/'+ subMerchant ); // Adjust the URL accordingly
            if (response.data) {
              const data = await response.data;
              setReports(data);


            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };

        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        fetchData()
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
}, [logout]);
  return (
    <>
      <PageTitle breadcrumbs={[]}>Submerchant Reports</PageTitle>
      <>
   <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>All Transactions For Submerchant ID:  {reports[0]?.submerchant.dba_name}</span>
          <button className='btn btn-success btn-sm' onClick={downloadCSV}>Download Report</button>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 row'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'
          id="table"
          >
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
              <th className='min-w-120px'>Attribute</th>
                <th className='min-w-120px'>Current Week</th>
                <th className='min-w-120px'>Week 3</th>

                <th className='min-w-120px'>Week 2</th>
                <th className='min-w-120px'>Week 1</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {reports.length > 0 && (
           <>
           <tr>

              <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>Sales</td>
                {reportNumbers.map((index)=>(
                            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>
                            {formatMoney(reports[index].total)+'('}
                            <small> {getPercentage(reports[index].total,reports[index+1].total)}% </small>
                                {reports[index].total > reports[index+1].total ? (
                                    <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                                //  /media/icons/duotune/arrows/arr001.svg
                                ) : (
                                    reports[index].total < reports[index+1].total && (
                                    <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                    )
                                )}
                                {")"}
                            </td>
                ))}

              <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>{formatMoney(reports[3].total)}</td>

            </tr>
            <tr>
            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>Sales Count </td>
            {reportNumbers.map((index)=>(
                            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>
                            {reports[index].count
                            +'('}
                            <small> {getPercentage(reports[index].count,reports[index+1].count)}% </small>
                                {reports[index].count > reports[index+1].count ? (
                                    <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                                //  /media/icons/duotune/arrows/arr001.svg
                                ) : (
                                    reports[index].total < reports[index+1].count && (
                                    <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                    )
                                )}
                                    {")"}
                            </td>
                ))}

              <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>{reports[3].count}</td>

            </tr>
            <tr>
            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>Sales Average </td>
            {reportNumbers.map((index)=>(
                            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>
                            {formatMoney(reports[index].average)    +'('}
                            <small> {getPercentage(reports[index].average,reports[index+1].average)}% </small>
                                {reports[index].average > reports[index+1].average ? (
                                    <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                                //  /media/icons/duotune/arrows/arr001.svg
                                ) : (
                                    reports[index].average < reports[index+1].average && (
                                    <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                    )
                                )}
                                 {")"}
                            </td>
                ))}

              <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>{formatMoney(reports[3].average)}</td>

            </tr>
            <tr>
            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>Refund Count </td>
            {reportNumbers.map((index)=>(
                            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>
                            {reports[index].refund   +'('}
                            <small> {getPercentage(reports[index].refund,reports[index+1].refund)}% </small>
                                {reports[index].refund > reports[index+1].refund ? (
                                    <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                                //  /media/icons/duotune/arrows/arr001.svg
                                ) : (
                                    reports[index].refund < reports[index+1].refund && (
                                    <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                    )
                                )}
                                 {")"}
                            </td>
                ))}

              <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>{reports[3].refund}</td>

            </tr>
            <tr>
            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>Refund</td>
            {reportNumbers.map((index)=>(
                            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>
                            {formatMoney(reports[index].refundVolume)   +'('}
                            <small> {getPercentage(reports[index].refundVolume,reports[index+1].refundVolume)}% </small>
                                {reports[index].refundVolume > reports[index+1].refundVolume ? (
                                    <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                                //  /media/icons/duotune/arrows/arr001.svg
                                ) : (
                                    reports[index].refundVolume < reports[index+1].refundVolume && (
                                    <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                    )
                                )}
                                {')'}
                            </td>
                ))}

              <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>{formatMoney(reports[3].refundVolume)}</td>

            </tr>
            <tr>

                <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>Chargeback </td>
                {reportNumbers.map((index)=>(
                            <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>
                            {formatMoney(reports[index].chargebackVolume)   +'('}
                            <small> {getPercentage(reports[index].chargebackVolume,reports[index+1].chargebackVolume)}% </small>
                                {reports[index].chargebackVolume > reports[index+1].chargebackVolume ? (
                                    <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                                //  /media/icons/duotune/arrows/arr001.svg
                                ) : (
                                    reports[index].chargebackVolume < reports[index+1].chargebackVolume && (
                                    <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                    )
                                )}
                                {')'}
                            </td>
                ))}

              <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>{formatMoney(reports[3].chargebackVolume)}</td>

            </tr>
            <tr>

                <td className='text-dark fw-bold text-hover-primary fs-6'>Chargeback Count </td>
                {reportNumbers.map((index)=>(
                            <td className='text-dark fw-bold text-hover-primary px-3 fs-6'>
                            {reports[index].chargeback   +'('}
                            <small> {getPercentage(reports[index].chargeback,reports[index+1].chargeback)}% </small>
                                {reports[index].chargeback > reports[index+1].chargeback ? (
                                    <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                                //  /media/icons/duotune/arrows/arr001.svg
                                ) : (
                                    reports[index].chargeback < reports[index+1].chargeback && (
                                    <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                    )
                                )}
                                {')'}
                            </td>
                ))}

              <td className='text-dark fw-bold text-hover-primary px-3 fs-6'>{reports[3].chargeback}</td>

             </tr>
             <tr>
             <td className='text-dark fw-bold text-hover-primary fs-6 px-3' >Refund %</td>
                {reportNumbers.map((index)=>(
                        <td className={`text-dark fw-bold text-hover-primary fs-6 ${reports[index].refund_status === 'Danger' ? 'danger'
                         : reports[index].refund_status==='Warning' ?  'warning':''}`}>
                        {reports[index].refundRatio  +'% ('}
                        <small> {getPercentage(reports[index].refundRatio,reports[index+1].refundRatio)}% </small>
                            {reports[index].refundRatio > reports[index+1].refundRatio  ? (
                                <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                            //  /media/icons/duotune/arrows/arr001.svg
                            ) : (
                                reports[index].refundRatio < reports[index+1].refundRatio && (
                                <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                )
                            )}
                            {')'}
                        </td>
                ))}
                <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>{reports[3].refundRatio }%</td>
             </tr>
             <tr>
             <td className='text-dark fw-bold text-hover-primary fs-6 px-3'>Chargeback %</td>
                {reportNumbers.map((index)=>(
                            <td className={`text-dark fw-bold text-hover-primary fs-6 ${reports[index].chargeback_status === 'Danger' ? 'danger'
                                             : reports[index].chargeback_status==='Warning' ?  'warning':''}`}>
                        {reports[index].chargeBackRatio    +'% ('}
                        <small> {getPercentage(reports[index].chargeBackRatio ,reports[index+1].chargeBackRatio )}% </small>
                            {reports[index].chargeBackRatio   > reports[index+1].chargeBackRatio   ? (
                                <KTSVG path='/media/icons/duotune/arrows/arr066.svg' className='svg-icon-success ms-1' />
                                            //  /media/icons/duotune/arrows/arr001.svg
                            ) : (
                                reports[index].chargeBackRatio   < reports[index+1].chargeBackRatio  && (
                                <KTSVG path='/media/icons/duotune/arrows/arr065.svg' className='svg-icon-danger ms-1' />
                                )
                            )}
                            {')'}
                        </td>
                ))}
                <td className='text-dark fw-bold text-hover-primary fs-6'>{reports[3].chargeBackRatio  }%</td>
             </tr>
             </>
 )}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    </>
    </>
  )
}

export default SubmerchantReportPage
