import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import { getBaseUrl } from '../customFunctions/customFunctions'
import axios from 'axios'
import { useAuth } from '../auth'


// const DailyReportPage: React.FC = () => (


// )
interface Reports {
  report:Report[]
}
interface Report {
  id: string;
  name: string;
  total_amount: number;
  count: number;
  // Add any other properties as needed
}

const TopProcessingPage: FC = () => {
  const base = getBaseUrl()
  const { logout } = useAuth()
  const [reports, setReports] = useState<Report[][]>([]);


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/top_processing' ); // Adjust the URL accordingly
            if (response.data) {
              console.log(response);
              const data = await response.data;
              setReports(data);
            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };

        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        fetchData()
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
}, [logout]);
  return (
    <>
      <PageTitle breadcrumbs={[]}>Top Processing Submerchants</PageTitle>
      <div className='row flex space-x-15'>
   <div className={`card col-6`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Submerchants with highest Sales volume</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 row'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-50px'>SID</th>
                <th className='min-w-100px'>Name</th>
                <th className='min-w-50px'>Total Volume</th>
                <th className='min-w-30px'>Total Count</th>
                <th className='min-w-50px'>Action</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {reports.length > 0 && (
            reports[0].map((report:any, index:any) => (

            <>
            <tr key={index}>
              <td className='text-dark fw-bold text-hover-primary fs-6' >{report.id}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.name}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.total_amount}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.count}</td>
              <td className='text-dark fw-bold text-hover-danger fs-6'><a href={'submerchant/weekly/'+report.id}>Review</a></td>

            </tr>
            </>

          ))
          )}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <div className={`card col-5 ms-12`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Submerchants with highest Refunds</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 row'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-50px'>SID</th>
                <th className='min-w-100px'>Name</th>
                <th className='min-w-50px'>Total Volume</th>
                <th className='min-w-30px'>Total Count</th>
                <th className='min-w-50px'>Action</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {reports.length > 0 && (
            reports[1].map((report, index) => (

            <>
            <tr key={index}>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.id}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.name}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.total_amount}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.count}</td>
              <td className='text-dark fw-bold text-hover-danger fs-6'><a href={'submerchant/weekly/'+report.id}>Review</a></td>

            </tr>
            </>

          ))
          )}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    </div>
    <div className={`card col-8 mt-8`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Submerchants with highest Chargebacks</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 row'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-50px'>SID</th>
                <th className='min-w-100px'>Name</th>
                <th className='min-w-50px'>Total Volume</th>
                <th className='min-w-30px'>Total Count</th>
                <th className='min-w-50px'>Action</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {reports.length > 0 && (
            reports[2].map((report, index) => (

            <>
            <tr key={index}>
              <td className='text-dark fw-bold text-hover-primary fs-6' >{report.id}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.name}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.total_amount}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{report.count}</td>
              <td className='fw-bold text-success fs-6 text-hover-primary'><a href={'submerchant/weekly/'+report.id}>Review</a></td>

            </tr>
            </>

          ))
          )}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    </>
  )
}

export default TopProcessingPage
