import React, { useEffect } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from 'axios'
import { useAuth } from '../auth'
import { formatDate } from '../customFunctions/customFunctions'
import { useParams } from 'react-router-dom'


interface Card {
    id: string;
    cardholder: string;
}


interface Transaction {
    id : string;
    card: Card;
    amount: string;
}

interface Submerchant {
    name: string
    dba_name: string
    mcc: string
    total_sum: string
    average: string
    descriptionOfGoods: string
    total_count: string
    monthly_processing_limit: string
    refund: string
    chargeback: string
    pciCompliance: string
    financialReviewNotes: string
    businessReviewNotes: string
    riskManagement: string
    reviewConclusions: string
    reviewPreparedBy: string
    riskReviewCompletedBy: string
    reviewApprovedBy: string;
    transactions:Transaction[];
    }
 const API_URL = process.env.REACT_APP_API_URL
export default function LargeSubmerchant() {
    const { subMerchant } = useParams();
    const { logout } = useAuth()
  
    useEffect(() => {
     
        const fetchData = async () => {
            console.log('fetching data')
            const result = await axios(
                `${API_URL}api/large-submerchant-review/${subMerchant}`,
            )
            console.log(result.data)
            if (result.data) {
            setSubmerchant(result.data)
            }
            else {
                console.log('No data found')
            }
           
        }
        
        const interceptor = axios.interceptors.response.use(
          
            response => response,
            error => {
              if (error.response && error.response.status === 401) {
                logout();
              }
              return Promise.reject(error);
            }
          );
          fetchData()
          return () => {
            axios.interceptors.response.eject(interceptor);
           
          };
    }
    , [logout]
    )
    const user = useAuth().auth?.user
    const [submerchant, setSubmerchant] = React.useState<Submerchant>({} as Submerchant)

  return (
    <>
    <PageTitle breadcrumbs={[]}>Large Submerchant</PageTitle>
    <div className='flex space-x-15'>
        <div className='card '>
            <div className='fw-bolder text-center p-3'>Submerchant Information</div>
            <div className='card-body'>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Submerchant Name</span>
            <span className='col-10 fw-bold text-dark border border-dark p-3'>{submerchant.name}</span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Submerchant Dba</span>
            <span className='col-10 fw-bold text-dark border border-dark p-3'>{submerchant.dba_name}</span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Submerchant MID's</span>
            <span className='col-10 fw-bold text-muted border border-dark p-3'>{submerchant.mcc}</span>
            </div>
            <div className='row p-2'>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Approved Annual Volume</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>{submerchant.monthly_processing_limit}</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Approved Average Ticket</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3'>{submerchant.average}</span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Description Of Goods and services
            provided and Target Cardholder</span>
            <span className='col-10 fw-bold text-muted border border-dark p-3'>{
            submerchant.transactions ? submerchant.transactions[0].card ? submerchant.transactions[0].card.cardholder : '' : ''
            }</span>
            </div>
            <div className='row p-2'>
            <span className='col-12 fw-bold text-center text-dark border border-dark p-3'>12 Month snapshot</span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Processed Volume</span>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>{submerchant.total_sum}</span>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Transaction Count</span>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>{submerchant.total_count}</span>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'></span>
            <span className='col-2 fw-bold text-muted border border-dark p-3'></span>
            </div>
            <div className='row p-2'>
            <span className='col-4 fw-bold text-dark border border-dark p-3 border-right-0'>Refund %</span>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>{submerchant.refund}</span>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Chargeback %</span>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>{submerchant.chargeback}</span>
          
            <span className='col-2 fw-bold text-muted border border-dark p-3'></span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>PCI Compliance Validation
            <br /> Requirements/Status
            </span>
            <span className='col-10 fw-bold text-muted border border-dark p-3'></span>
            </div>
            <div className='row p-2'>
            <span className='col-12 fw-bold text-center text-dark border border-dark p-3'>Review notes</span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Financial Review Notes
         
            </span>
            <span className='col-10 fw-bold text-muted border border-dark p-3'></span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Business/Principal Review Notes
           
            </span>
            <span className='col-10 fw-bold text-muted border border-dark p-3'></span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Risk Management 
            <br /> Comments/Concerns
            </span>
            <span className='col-10 fw-bold text-muted border border-dark p-3'></span>
            </div>
            <div className='row p-2'>
            <span className='col-2 fw-bold text-dark border border-dark p-3 border-right-0'>Review Conclusions & Risk
            <br /> Recommendations/ <br /> Ongoing Conditions:
            </span>
            <span className='col-10 fw-bold text-muted border border-dark p-3'></span>
            </div>
            <div className='row p-2'>
            <span className='col-12 fw-bold text-center text-dark border border-dark p-3'>Approvals</span>
            </div>
            <div className='row p-2'>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Review Prepared By:</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>{user?.name}</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Date:</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3'>{formatDate(new Date().toISOString())}</span>
            </div>
            <div className='row p-2'>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Risk Review Completed By:</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'></span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Date:</span>
            <span className='col-3 fw-bold text-muted border border-dark p-3'></span>
            </div>
            <div className='row p-2'>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Review Approved By:</span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'></span>
            <span className='col-3 fw-bold text-dark border border-dark p-3 border-right-0'>Date:</span>
            <span className='col-3 fw-bold text-muted border border-dark p-3'></span>
            </div>
            </div>
          

        </div>
    </div>
    </>
  )
}
