import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import { getBaseUrl } from '../../customFunctions/customFunctions'

const API_URL = process.env.REACT_APP_API_URL
const base = getBaseUrl();
axios.defaults.withCredentials = true;

// export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
// export const LOGIN_URL = `${API_URL}/login`
// export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`


// Server should return AuthModel
export async function login(email: string, password: string): Promise<AuthModel> {
  try {
    const csrfResponse = await axios.get(base+'api/csrf-cookie');
    console.log(csrfResponse);

    const response = await axios.post<AuthModel>(base+'api/login', {
      email,
      password,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  // return axios.post(REGISTER_URL, {
  //   email,
  //   first_name: firstname,
  //   last_name: lastname,
  //   password,
  //   password_confirmation,
  // })
}

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }
