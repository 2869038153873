import { FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import DailyReportPageWrapper from '../modules/dailyReport/DailyReportPage'
import OLReportPage from '../modules/overLimit/OLReportPage'
import SubmerchantTransactionPage from '../modules/dailyReport/SubmerchantTransactionPage'
import SubmerchantControls from '../modules/submerchant/SubmerchantControls'
import SubmerchantPanel from '../modules/submerchant/SubmerchantPanel'
import SubmerchantReportPage from '../modules/overLimit/SubmerchantReportPage'
import SubmerchantWeeklyReportPage from '../modules/overLimit/SubmerchantWeeklyReportPage'
import TopProcessingPage from '../modules/TopProcessingPage/TopProcessingPage'
import CreateUser from '../modules/Users/CreateUsers'
import WatchlistPage from '../modules/WatchlistPage/WatchlistPage'
import RolesPage from '../modules/roles/RolesPage'
import MatchlistPage from '../modules/MatchlistPage/MatchlistPage'
import DailyWatchlistPage from '../modules/WatchlistPage/dailyWatchlistPage'
import DisplayPage from '../modules/Automation/DisplayPage'
import Create from '../modules/Automation/Create'
// import UnderwritingPage from '../modules/underwriting/UnderWritingPage'
import LargeSubmerchant from '../modules/LargeSubmerchant/LargeSubmerchant'
import {Login} from '../modules/auth/components/Login'
import FintracPage from '../modules/Fintrac/FintracPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import ViewReport from '../modules/Fintrac/ViewReport'
import CreateTtype from '../modules/Automation/CreateTtype'
// import AdminPage from '../modules/Admin/AdminPage'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/daily-report' />} />
        {/* Pages */}
        <Route  path='login' element={<Login />} />
        <Route  path='create-user' element={<CreateUser />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='create-ttype' element={<CreateTtype />} />
        <Route path='/view-report/:trans' element={<ViewReport />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='daily-report' element={<DailyReportPageWrapper />} />
        <Route path='ol-report' element={<OLReportPage />} />
        <Route path='top-processing' element={<TopProcessingPage />} />
        <Route path='matchlist' element={<MatchlistPage />} />
        <Route path='watchlist' element={<WatchlistPage />} />
        <Route path='daily-watchlist' element={<DailyWatchlistPage />} />
        <Route path='roles' element={<RolesPage />} />
        <Route path='fintrac' element={<FintracPage />} />
        <Route path='display' element={<DisplayPage />} />
        <Route path='create' element={<Create />} />
        <Route path="submerchant-controls" element={<SubmerchantControls />} />
        <Route path="submerchant/controls/:subMerchant" element={<SubmerchantPanel />} />
        <Route path='large-submerchant/:subMerchant' element={<LargeSubmerchant />} />
        <Route path="submerchant/weekly/:subMerchant" element={<SubmerchantWeeklyReportPage />} />
        {/* <Route path="restricted-submerchants" element={<UnderwritingPage />} /> */}
        <Route path="ol-report/submerchant/report/:subMerchant" element={<SubmerchantReportPage />} />
        <Route path="daily-report/submerchant/:subMerchant" element={<SubmerchantTransactionPage />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
