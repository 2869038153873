import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { getBaseUrl,formatDate} from '../customFunctions/customFunctions';
import { format } from 'path';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faPen } from '@fortawesome/free-solid-svg-icons';

// ...
interface Role {
    id: number;
    name: string;
}

const initialRoles: Role[] = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'User' },
];
interface Permission {
    id: number;
    name: string;
}

const initialPermissions: Permission[] = [
    { id: 1, name: 'Read' },
    { id: 2, name: 'Write' },
];

const RolesPage: React.FC = () => {
    const base = getBaseUrl();
    
    const [roles, setRoles] = useState<Role[]>([]);
    const [newRole, setNewRole] = useState<string>('');
    const [newId, setNewId] = useState<string>('');
    const [permissions, setPermissions] = useState<Permission[]>(initialPermissions);
    const [checkedPermissions, setCheckedPermissions] = useState<string[]>([]);

    const handleCheckboxChange = (event:any) => {
        const { value, checked } = event.target;
        if (checked) {
            setCheckedPermissions([...checkedPermissions, value]);
        } else {
            setCheckedPermissions(checkedPermissions.filter(permission => permission !== value));
        }
    };
    useEffect(() => {
        try {
            const fetchRoles = async () => {
                const response = await axios.get(`${base}api/roles`); // Adjust the URL accordingly
                if (response.status === 200) {
                    // console.log(response.data.roles);
                    setRoles(response.data.roles);
                } else {
                    console.error('Failed to fetch data');
                }
            };
            const fetchPermissions = async () => {
                const response = await axios.get(`${base}api/permissions`); // Adjust the URL accordingly
                if (response.status === 200) {
                    setPermissions(response.data.permissions);
                } else {
                    console.error('Failed to fetch data');
                }
            }
            fetchPermissions();
            fetchRoles();
        }
        catch (error) {
            console.error('An error occurred during the fetch', error);
        }
    
        // Fetch roles from the API
    }, []);
    const handleDelete = (id:any) => {
        try {
            const deleteRole = async () => {
                const response = await axios(`${base}api/delete-roles/${id}`);
                if (response.status === 200) {
                    setRoles(roles.filter(role => role.id !== id));
                } else {
                    console.error('Failed to delete role');
                }
            };
            deleteRole();
        }
        catch (error) {
            console.error('An error occurred during the fetch', error);
        }
    }
    const handleSelect = (role:any) => {
        setNewRole(role.name);
        setNewId(role.id.toString());
        setCheckedPermissions(role.permissions.map((permission:any) => permission.id.toString()));
    }
    const handleAddRole = () => {
        if (newId){
            const updateRole = async () => {
                const response = await axios.post(`${base}api/update-roles/${newId}`, {
                    name: newRole,
                    permissions: checkedPermissions,
                });
                if (response.data) {
                    setRoles(roles.map(role => role.id === response.data.role.id ? response.data.role : role));
                    setNewRole('');
                    setNewId('')
                    setCheckedPermissions([]);
                } else {
                    console.error('Failed to update role');
                }
            };
            updateRole();
            return;
        }
        try {
            const addRole = async () => {
                console.log(checkedPermissions)
                const response = await axios.post(`${base}api/roles`, {
                    name: newRole,
                    permissions: checkedPermissions,
                });
                if (response.data) {
                    setRoles([...roles, response.data.role]);
                } else {
                   
                    console.error('Failed to add role');
                }
            };
            addRole();
        }
        catch (error) {
            console.error('An error occurred during the fetch', error);
        }

    };

    return (
        <div className='row flex space-x-15'>
        <div className={`card  col-7`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>All Roles</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 row'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-30px'>ID</th>
                <th className='min-w-100px'>Name</th>
                <th className='min-w-100px'>Created_at</th>
                <th className='min-w-150px'>Permissions</th>
                <th className='min-w-50px'>Actions</th>
              


              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {roles ?
            roles.map((role:any, index:any) => (
            <tr key={index} onClick={()=>handleSelect(role)}>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{role.id}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{role.name}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{formatDate(role.created_at)}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>
                {role.permissions.map((permission:any, index:any) => (
                    <span key={index} className='badge badge-light-primary me-2'>{permission.name}</span>
                ))}    
            </td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>
               

                <span className="text-danger mx-2">
                   
                    <FontAwesomeIcon icon={faTrash} onClick = { () => handleDelete(role.id)} />

                </span>
                <span className="text-info mx-2">
                   
                   <FontAwesomeIcon icon={faPen} />

               </span>
                </td>


            </tr>
          )):<p>not found</p>
        }

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
        </div>
        <div className={` card col-4 mb-xl-10 col-4 ms-12`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Create New Role</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{}</span>
            </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 row'>
           
            <div className='form-group'>
                <input type="hidden" name="_token" value="csrf_token()" />
                <input type="text" name="id" value={newId} hidden />
                <input
                className='form-control form-control-lg form-control-solid'
                    type="text"
                    name="name"
                    value={newRole}
                    onChange={(e) => setNewRole(e.target.value)}
                />
                <div className="mt-3 row p-3">
                {permissions.map((permission) => {
                    return (
                        <div key={permission.id} className="form-check col">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={permission.id}
                                checked={checkedPermissions.includes(permission.id.toString())}
                                onChange={handleCheckboxChange}
                                name="permissions[]"
                                id={`permission-${permission.id}`}
                            />
                            <label className="form-check-label" htmlFor={`permission-${permission.id}`}>
                                {permission.name}
                            </label>
                        </div>
                    );
                })}
                </div>
                <button className="btn btn-success form-control"onClick={handleAddRole}>Add Role</button>
                </div>
            </div>
            
        </div>
       
    </div>
    );
};

export default RolesPage;