import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Drawer,Box } from '@material-ui/core'
import {Button} from '@material-ui/core'
import {Typography} from '@material-ui/core'

import '../../../paginate.css'
// import { fetchCsrfToken } from './getToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAnglesRight, faCoffee } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { getBaseUrl,checkPermission } from '../customFunctions/customFunctions'
import Swal from 'sweetalert2';
import {useAuth} from '../auth/core/Auth'
import { toast,ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CheckBox } from '@material-ui/icons'

// const DailyReportPage: React.FC = () => (


// )


    interface Submerchant {
        id: string;
        name: string;
        email:string;
        phone: string;
        dba_name:string;
        // Add any other fields based on your requirements
    }




const SubmerchantControls: FC = () => {
    const {auth, setCurrentUser} = useAuth()
    const user = auth?.user;
    const permissions = auth?.permissions;
    const [submerchants,setSubmerchants] = useState<Submerchant[]>([]);
    // const [roles,setRoles] = useState<Roles[]>([]);
    const [data,setData] = useState({current_page: 1, last_page: 1 });
    console.log(permissions)

    const [image, setImage] = useState("");
    const element = <FontAwesomeIcon icon={faAngleRight} size='2x' />
  
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    

    






 



        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const base = getBaseUrl()
       
        // const currentItems = transactions.slice(itemOffset, endOffset);
        // const pageCount = Math.ceil(transactions.length / itemsPerPage);
        const { logout } = useAuth()
        // const csrf_token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'); 
       
        const [isChecked, setIsChecked] = useState(false);
       

        


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/get-submerchants?page='+data.current_page); // Adjust the URL accordingly
            if (response.data) {
              const myData = response.data.data;
              setSubmerchants(myData);
              console.log(myData);
              setData({current_page: response.data.current_page, last_page: response.data.last_page });
              console.log(data)
            //   setTransactions(data);

            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };
        
        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );

        fetchData()
       
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
       

       
      }, [data.current_page,logout]);

      function handleClick(id:string){
        window.location.href = `/submerchant/controls/${id}`;
      }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Submerchants</PageTitle>
      <div className='flex space-x-15'>
       <ToastContainer />


    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Select Submerchant</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive '>
             
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
              
                <th className='min-w-50px'>SN</th>
                <th className='min-w-50px'>Name</th>
                <th className='min-w-50px'>DBA</th>
                <th className='min-w-100px'>Email</th>
            
              
      

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {submerchants.map((submerchant, index) => (
            <tr key={index} onClick={()=>handleClick(submerchant.id)} >
            {/* <td><input type="checkbox" name="selectedSubmerchants" className='form-control ' /></td> */}

              <td className='text-dark fw-bold text-hover-primary fs-6' >{index + 1}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{submerchant.name}
            </td>
            <td className='text-dark fw-bold text-hover-primary fs-6'>{submerchant.dba_name}
            </td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>
                {submerchant.email}
                </td>
           


            </tr>
          ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

          <Box display="flex" justifyContent="center" alignItems="center" className='mb-10'>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setData({ ...data, current_page: data.current_page - 1 })}
              disabled={data.current_page === 1}
            >
              Previous
            </Button>

            <Typography variant="subtitle1" style={{ margin: '0 10px' }}>
              Page {data.current_page} of {data.last_page}
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setData({ ...data, current_page: data.current_page + 1 })}
              disabled={data.current_page === data.last_page}
            >
              Next
            </Button>
        </Box>
        
  

        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>


  
    </div>
    </>
  )
}

export default SubmerchantControls
