import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { KTSVG } from '../../../_metronic/helpers'
import { useEffect,useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Drawer,Box } from '@material-ui/core'

import '../../../paginate.css'
// import { fetchCsrfToken } from './getToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faAnglesRight, faCoffee } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { getBaseUrl,checkPermission } from '../customFunctions/customFunctions'
import Swal from 'sweetalert2';
import {useAuth} from '../auth/core/Auth'
import { toast,ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {Button} from '@material-ui/core'
import {Typography} from '@material-ui/core'

// const DailyReportPage: React.FC = () => (


// )
function formatMoney(amount:any){
    amount = parseFloat(amount)
    const formattedAmount = amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });

  return formattedAmount
}
interface Submerchant {
    id: string;
    name: string;
    email:string;
    phone: string;
    // Add any other fields based on your requirements
}
interface TransactionType {
    id: string;
    name: string;
    // Add any other fields based on your requirements
}

  interface Checklist {
    id: string;
    name: string;
    status: boolean;
  }
interface Transaction {
    number: string;
    amount: number;
    transaction_type_id: number;
    submerchant_id: string;
    card_id: string;
    batch: string;
    batch_amount: number;
    submerchant:Submerchant;
    collection_amount: number;
    terminal_number: string;
    cardholder: string;
    status: boolean;
    manual_entry: boolean;
    swiped_mag_stripe: boolean;
    time_created: string;
    transaction_type:TransactionType;
    t_type:string;
    // Add any other fields based on your requirements
}
interface Image{
    id:string;
    image:string;
}
interface Incidents{
    id:string;
    status:number;
    image:Image[];
    transaction:Transaction;
    checklist:Checklist[];
    notes:string;
    issue:string;
    analyst:string;
}
interface Rules{
    id:string;
    description:string;
    parameters:string;
    period:string;
    submerchant:Submerchant;
    status:string;
    value:number;
    decision:string;
}

const DisplayPage: FC = () => {
    const {auth, setCurrentUser} = useAuth()
    const user = auth?.user;
    const permissions = auth?.permissions;
    
    console.log(permissions)
    const [rules, setRules] =  useState<Rules[]>([]);
    const [image, setImage] = useState("");
    const element = <FontAwesomeIcon icon={faAngleRight} size='2x' />
    const [currentTransaction, setCurrentTransactions]  =useState<Incidents | null>(null);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    

    






 



        // Here we use item offsets; we could also use page offsets
        // following the API or data you're working with.
        const base = getBaseUrl()
        const [itemOffset, setItemOffset] = useState(0);
        // const [CsrfToken,setCsrfToken] = useState("");
        const itemsPerPage = 12;
        // Simulate fetching items from another resources.
        // (This could be items from props; or items loaded in a local state
        // from an API endpoint with useEffect and useState)
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        // const currentItems = transactions.slice(itemOffset, endOffset);
        // const pageCount = Math.ceil(transactions.length / itemsPerPage);
        const { logout } = useAuth()
        // const csrf_token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'); 
       


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios(base+'api/automation/'); // Adjust the URL accordingly
            if (response.data) {
              const data = response.data;
              setRules(data);
              console.log(data)
            //   setTransactions(data);

            } else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('An error occurred during the fetch', error);
          }
        };
        
        const interceptor = axios.interceptors.response.use(
          
          response => response,
          error => {
            if (error.response && error.response.status === 401) {
              logout();
            }
            return Promise.reject(error);
          }
        );
        fetchData()
        return () => {
          axios.interceptors.response.eject(interceptor);
         
        };
       

       
      }, [logout]);

  return (
    <>
      <PageTitle breadcrumbs={[]}>Automation Rules</PageTitle>
      <div className='flex space-x-15'>
       <ToastContainer />


    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Rules</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{new Date().toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</span>
        </h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive '>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-50px'>ID</th>
                <th className='min-w-50px'>description</th>
                <th className='min-w-100px'>Submerchant</th>
                <th className='min-w-100px'>parameters</th>
                <th className='min-w-100px'>decision</th>
                <th className='min-w-100px'>Threshold</th>
            
                <th className='min-w-50px'>Status</th>

              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {rules.map((rule, index) => (
            <tr key={index} >
              <td className='text-dark fw-bold text-hover-primary fs-6' >{rule.id}</td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>{rule.description}
            </td>
              <td className='text-dark fw-bold text-hover-primary fs-6'>
                {rule.submerchant.name}
                </td>
              <td className='text-dark fw-bold fs-6'>{rule.parameters}</td>
              <td className='text-dark fw-bold fs-6'>
                {rule.decision}
             </td>
             <td className='text-dark fw-bold fs-6'>
                ${rule.value}
             </td>

            </tr>
          ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}

        </div>

        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>


  
    </div>
    </>
  )
}

export default DisplayPage
