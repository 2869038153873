/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { checkPermission } from '../../../../../app/modules/customFunctions/customFunctions';
import { useAuth } from '../../../../../app/modules/auth';

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const myRole = auth?.user.role.id;

  const permissions = auth?.permissions;

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
     <SidebarMenuItem
        to='/daily-report'
        icon='/media/icons/duotune/general/gen005.svg'
        title='Daily Report'
        fontIcon='bi-app-indicator'
      />
        {/* <SidebarMenuItem
        to='/monthly-report'
        icon='/media/icons/duotune/general/gen014.svg'
        title='Monthly Report'
        fontIcon='bi-app-indicator'
      /> */}
              <SidebarMenuItem
        to='/ol-report'
        icon='/media/icons/duotune/general/gen014.svg'
        title='Out Of Limit Reports'
        fontIcon='bi-app-indicator'
      />
      {/* {checkPermission(permissions, 'Top submerchants') && ( */}
        <SidebarMenuItem
          to='/top-processing'
          icon='/media/icons/duotune/general/gen014.svg'
          title='Top Submerchants'
          fontIcon='bi-app-indicator'
        />
      {/* )} */}
       {/* {checkPermission(permissions, 'Underwrite') && ( */}
      <SidebarMenuItem
        to='/fintrac'
        icon='/media/icons/duotune/abstract/abs043.svg'
        title='Fintrac Report'
        fontIcon='bi-app-indicator'
      />
      {/* )} */}

    {/* <SidebarMenuItem
        to='/top-processing'
        icon='/media/icons/duotune/general/gen014.svg'
        title='Top Submerchants'
        fontIcon='bi-app-indicator'
      /> */}
        <SidebarMenuItemWithSub to='/display' title='Automation' hasBullet={true}>
        <SidebarMenuItem to='/display' title='View Rules' />
          <SidebarMenuItem to='/create' title='Create Rule' />
         
        </SidebarMenuItemWithSub>
        <SidebarMenuItem
        to='/watchlist'
        icon='/media/icons/duotune/general/gen013.svg'
        title='Watch List'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/create-ttype'
        icon='/media/icons/duotune/general/gen013.svg'
        title='Create Transaction Type'
        fontIcon='bi-app-indicator'
      />
                <SidebarMenuItem
        to='/submerchant-controls'
        icon='/media/icons/duotune/general/gen013.svg'
        title='Submerchant Controls'
        fontIcon='bi-app-indicator'
      />
    
    {myRole == '1' && (
    <SidebarMenuItem
        to='/create-user'
        icon='/media/icons/duotune/general/gen013.svg'
        title='Create User'
        fontIcon='bi-app-indicator'
      />
    )}

              {/* <SidebarMenuItem
        to='/matchlist'
        icon='/media/icons/duotune/general/gen013.svg'
        title='MATCH'
        fontIcon='bi-app-indicator'
      /> */}
  {myRole == '1' && (
      <SidebarMenuItem
        to='/roles'
        icon='/media/icons/duotune/communication/com009.svg'
        title='Roles'
        fontIcon='bi-app-indicator'
      />
  )}

      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={'https://preview.keenthemes.com/metronic8/react/docs' + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {'v8.1.7'}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
